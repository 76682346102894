import { Component, OnDestroy, OnInit } from '@angular/core';
import { Icon, OnboardingStatus, socialMediaLinks } from 'src/constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/toast/toast.service';
import { User, UserService } from '../../services/user.service';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-onboarding-layout',
	templateUrl: './onboarding-layout.component.html',
	styleUrls: ['./onboarding-layout.component.scss'],
	animations: [
		trigger('moveUpFade', [
			state('normal', style({
				transform: 'translateY(0)',
				opacity: 1,
			})),
			state('active', style({
				transform: 'translateY(-223px)',
				opacity: 0.7,
			})),
			transition('normal => active', [
				animate('600ms ease-in-out'),
			]),
		]),
		trigger('moveUp', [
			state('normal', style({
				transform: 'translateY(0)',
				opacity: 1,
			})),
			state('active', style({
				transform: 'translateY(-223px)',
				opacity: 1,
			})),
			transition('normal => active', [
				animate('600ms ease-in-out'),
			]),
		]),
		trigger('fadeIn', [
			state('normal', style({
				opacity: 0,
			})),
			state('active', style({
				opacity: 1,
			})),
			transition('normal => active', [
				animate('1s ease-in-out'),
			]),
		]),
		trigger('fade', [
			state('normal', style({
				opacity: 1,
			})),
			state('active', style({
				opacity: 0.7,
			})),
			transition('normal => active', [
				animate('500ms ease-in-out'),
			]),
		]),
	],
})

export class OnboardingLayoutComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly socialMediaLinks = socialMediaLinks;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected user: User;
	public user$ = new Subject<any>();

	public constructor(
		protected readonly router: Router,
		protected readonly authService: AuthService,
		protected userService: UserService,
		protected toastService: ToastService,
		private route: ActivatedRoute,
	) {}

	riotTrigger: string;
	riotButtonText: string;
	riotIcon: string;
	discordTrigger: string;
	discordButtonText: string;
	discordIcon: string;
	discordIsHidden: boolean;
	finishIsHidden: boolean;

	async ngOnInit() {
		this.riotTrigger = 'normal';
		this.riotButtonText = 'Sign in to Riot';
		this.riotIcon = '/assets/images/riot-logo.svg';
		this.discordTrigger = 'normal';
		this.discordButtonText = 'Join the Discord';
		this.discordIcon = '/assets/images/discord.svg';
		this.discordIsHidden = true;
		this.finishIsHidden = true;

		// get user
		this.userService.reloadUser();
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
			console.log(user);

			if (user.onboardingStatus === OnboardingStatus.COMPLETED) {
				this.router.navigate(['/lol/matches/list']).then(() => console.log('onboarding was complete; navigating to matches'));
			} else if (user.onboardingStatus === OnboardingStatus.ONBOARDING) {
				// check riot
				if (user.puuid) {
					// if puuid is set that means riot account is set
					this.riotTrigger = 'active';
					this.riotButtonText = 'Connected';
					this.riotIcon = '/assets/images/check.svg';
					this.discordIsHidden = false;

					// check discord
					if (user.discordUserId) {
						// if user discord is set that means discord is connected
						this.discordTrigger = 'active';
						this.discordButtonText = 'Connected';
						this.discordIcon = '/assets/images/check.svg';
						this.finishIsHidden = false;

						this.userService.updateOnboarding(OnboardingStatus.COMPLETED);
					}
				}
			} else if (user.onboardingStatus === OnboardingStatus.NEW) {
				console.log('Onboarding started..');
			}

			this.route.queryParams.subscribe((params) => {
				const error = params['error'];
				if (error) {
					this.toastService.error(decodeURIComponent(error));
				}
			});
		});
	}

	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

	async onRiotClick() {
		await this.userService.updateOnboarding(OnboardingStatus.ONBOARDING);
		// redirect to riot login
		window.location.href = `${environment.apiUrl}/api/user/v1/login?jwt=${AuthService.getToken()}`;
	}

	onDiscordClick() {
		const page = 'lol';
		window.location.href = `${environment.csApiUrl}/discord/login?jwt=${AuthService.getToken()}&state=${page}`;
	}

	onLogout() {
		this.authService.logout();
		window.location.href = `${environment.csFrontendBaseUrl}/auth/login?limitedTestLogout=1`;
	}

	onViewMatchHistoryClick = () => {
		console.log(this.router);
		this.router.navigate(['/lol/matches/list']).then(() => console.log('navigated to match list history'));
	};
}
