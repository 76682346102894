import { Component, Input } from '@angular/core';
import { Icon } from 'src/constants';

/**
 * Use `font-size` to change the icon's size.
 *
 * NOTE: Not all icons use a square aspect ratio.
 */
@Component({
	selector: 'leetify-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
	@Input() public icon: Icon;
	@Input() public withFill: boolean = false;
	@Input() public fillColor: string = 'currentColor';

	protected readonly Icon = Icon;
}
