<aside>
	<div class="mobile-nav-toggle">
		<button aria-label="Toggle Menu" title="Toggle Menu" (click)="toggleNav($event)">
			<i class="mdi {{ navigationActive ? 'mdi-close' : 'mdi-menu' }}"></i>
		</button>

		<a [routerLink]="user ? '/lol' : '/'">
			<img class="desktop" src="/assets/images/leetify-logo-primary-white.svg" alt="Leetify">
			<img class="mobile" src="/assets/leetify/leetify-icon.svg" alt="Leetify">
		</a>
	</div>

	<div *ngIf="user" class="right">

		<app-user-menu></app-user-menu>

		<a routerLink="/app/profile" class="user-account-settings-link">
			<img [src]="'/assets/images/anon.png'" alt="" class="rounded-circle">
		</a>
	</div>
</aside>
