import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { Icon, socialMediaLinks } from 'src/constants';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../toast/toast.service';

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnDestroy, OnInit {
	protected readonly Icon = Icon;
	protected readonly isBrowser: boolean;
	protected readonly ngUnsubscribe = new Subject<void>();
	protected readonly socialMediaLinks = socialMediaLinks;
	protected isDataSourcesSubmenuExpanded: boolean = false;
	protected isDiscordConnected: boolean;

	protected user: User;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		private readonly authService: AuthService,
		private readonly userService: UserService,
		protected readonly toastService: ToastService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	private handleUser(user: User): void {
		this.user = user;
	}
	protected toggleDataSourcesSubmenu(isExpanded = !this.isDataSourcesSubmenuExpanded): void {
		this.isDataSourcesSubmenuExpanded = isExpanded;
	}

	protected async disconnectRiot(): Promise<void> {
		const didDisconnectSuccessfully = await this.userService.disconnectRiot();

		if (didDisconnectSuccessfully) {
			this.toastService.success('Successfully disconnected your Riot account.');
		} else {
			this.toastService.error('Failed to disconnect your Riot account :(');
		}
	}

	protected async changeDiscordNotificationsSettings() {
		const response = await this.userService.changeDiscordNotificationsSettings();

		if (response) {
			if (this.isDiscordConnected) {
				this.toastService.success('Successfully disabled Discord notifications');
				this.isDiscordConnected = false;
			} else {
				this.toastService.success('Successfully enabled Discord notifications');
				this.isDiscordConnected = true;
			}
		} else {
			this.toastService.error('Failed to change Discord notifications settings :(');
		}
	}

	protected logout(): void {
		this.authService.logout();
		window.location.href = `${environment.csFrontendBaseUrl}/auth/login?limitedTestLogout=1`;
	}

	public ngOnInit(): void {
		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => {
			this.handleUser(user);
			this.isDiscordConnected = user.discordNotifications;
		});

		this.handleUser(this.userService.user);
	}

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
