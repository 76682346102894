<nav>
	<ul class="sidebar-menu">
		<li>
			<a routerLink="/lol/matches" routerLinkActive="active" queryParamsHandling="merge"
				 [routerLinkActiveOptions]="{ exact: true }">
				<i class="leet-matches sidebar-icon"></i>
				Matches
			</a>
		</li>

		<ng-container *ngIf="user">
			<hr class="mobile-only">

			<li class="mobile-only">
				<button (click)="logout()">
					<i class="fa fa-sign-out-alt sidebar-icon"></i>
					Logout
				</button>
			</li>

			<li class="mobile-only">
				<button class=" --data-sources-toggle" (click)="toggleDataSourcesSubmenu()">
					<i class="leet-datasources sidebar-icon"></i>
					<span>Data Sources</span>
					<leetify-icon [icon]="Icon.CARET_DOWN" class="caret" [ngClass]="{ '--rotate-180deg': isDataSourcesSubmenuExpanded }"></leetify-icon>
				</button>
				<leetify-expandable-vertical [isActive]="isDataSourcesSubmenuExpanded" [isAriaHiddenWhenInactive]="false">
					<div class="data-sources-menu">
						<button class="user-menu-button" (click)="disconnectRiot()">
							<img class="fa sidebar-icon riot-icon" [src]="'/assets/images/riot-icon.svg'" alt="Riot">
							<span>Disconnect Riot account</span>
						</button>
						<button *ngIf= "isDiscordConnected" class="user-menu-button logout-button" (click)="changeDiscordNotificationsSettings()">
							<i class="fab fa-discord sidebar-icon"></i>
							<span>Disable Discord notifications</span>
						</button>
						<button *ngIf="!isDiscordConnected" class="user-menu-button logout-button" (click)="changeDiscordNotificationsSettings()">
							<i class="fab fa-discord sidebar-icon"></i>
							<span>Enable Discord notifications</span>
						</button>
					</div>
				</leetify-expandable-vertical>
			</li>

		</ng-container>
	</ul>

	<div class="sidebar-social">
		<a
			[queryParams]="{ redirect: 'no' }"
			aria-label="Home Page"
			container="body"
			ngbTooltip="Go to Home Page"
			openDelay="300"
			routerLink="/"
			tooltipClass="default-tooltip"
		>
			<leetify-icon [icon]="Icon.HOME"></leetify-icon>
		</a>

		<a *ngFor="let social of socialMediaLinks" [href]="social.link" target="_blank" [attr.aria-label]="social.title">
			<leetify-icon [icon]="social.icon"></leetify-icon>
		</a>
	</div>
</nav>
