import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root',
})

export class AuthGuard implements CanActivate {
	public constructor(
		private readonly router: Router,
		private readonly authService: AuthService,
	) {
	}

	public async canActivate(route: ActivatedRouteSnapshot, router: RouterStateSnapshot): Promise<boolean | UrlTree> {
		if (this.authService.isAuthenticated()) {
			return true;
		}

		console.log('auth guard - not authenticated');
		await this.router.navigate(['/lol/onboarding']);
		return false;
	}
}
