import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import { filter } from 'rxjs/operators';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import moment from 'moment';
import { NgbNavModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { Router, Scroll } from '@angular/router';

import { APIInterceptor } from 'src/app/interceptors/APIInterceptor';
import { AppComponent } from 'src/app/app.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AtomsModule } from 'src/app/components/atoms/atoms.module';
import { AuthResolver } from 'src/app/auth/auth.resolver';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@NgModule({
	declarations: [
		AppComponent,
	],

	imports: [
		AppRoutingModule,
		AtomsModule,
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		JwtModule.forRoot({ config: { tokenGetter: AuthService.getToken, allowedDomains: [environment.apiUrl.split('://').pop()] } }),
		NgbNavModule,
		NgbProgressbarModule,
		NgbTooltipModule,
		NgSelectModule,
		ReactiveFormsModule,
	],

	providers: [
		AuthResolver,
		CurrencyPipe,
		Title,
		TitleCasePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: APIInterceptor,
			multi: true,
		},
		provideHttpClient(withFetch(), withInterceptorsFromDi()),
	],

	bootstrap: [AppComponent],
})
export class AppModule {
	public constructor(router: Router) {
		router.events.pipe(
			filter((e): e is Scroll => e instanceof Scroll),
		).subscribe(() => {
			if (typeof document === 'undefined') return;
			const $layoutContent = document.querySelector('main');
			if ($layoutContent) $layoutContent.scrollIntoView();
		});

		moment.updateLocale('en', { week: { dow: 1 } }); // tells moment that weeks start on monday (not necessarily super clean to do apply this across the entire app, but much easier)
	}
}
