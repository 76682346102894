<div class="user-menu-container" [ngClass]="{ active: isMenuActive }">
	<div class="user" (click)="toggleDropdown()">
		<img [src]="'/assets/images/anon.png'" alt="" class="rounded-circle">
		<div class="text-truncate">
			{{ user?.userName }}#{{user?.userTag}}
		</div>

		<div class="spacer"></div>

		<div class="caret" [ngClass]="{ '--rotate-180deg': isMenuActive }">
			<i class="fas fa-caret-down ml-2"></i>
		</div>
	</div>

	<div class="user-dropdown">
		<div class="dropdown-content">
			<button class="user-menu-button --data-sources-toggle" (click)="toggleDataSourcesSubmenu()">
				<i class="leet-datasources user-menu-icon"></i>
				<span>Data Sources</span>
				<leetify-icon [icon]="Icon.CARET_DOWN" class="caret" [ngClass]="{ '--rotate-180deg': isDataSourcesSubmenuExpanded }"></leetify-icon>
			</button>

			<leetify-expandable-vertical [isActive]="isDataSourcesSubmenuExpanded" [isAriaHiddenWhenInactive]="false">
				<div class="data-sources-menu">
					<button class="user-menu-button logout-button" (click)="disconnectRiot()">
						<img class="fa fa-sign-out-alt riot-icon" [src]="'/assets/images/riot-icon.svg'" alt="Riot">
						<span>Disconnect Riot account</span>
					</button>
					<button *ngIf= "isDiscordConnected" class="user-menu-button logout-button" (click)="changeDiscordNotificationsSettings()">
						<i class="fab fa-discord user-menu-icon"></i>
						<span>Disable Discord notifications</span>
					</button>
					<button *ngIf="!isDiscordConnected" class="user-menu-button logout-button" (click)="changeDiscordNotificationsSettings()">
						<i class="fab fa-discord user-menu-icon"></i>
						<span>Enable Discord notifications</span>
					</button>
				</div>
			</leetify-expandable-vertical>

			<div class="discord-box user-menu-button">
				<a href="https://discord.gg/UNygC8BAVg" target="_blank">
					<i class="fab fa-discord user-menu-icon"></i>
					<span>Join our Discord</span>
				</a>
			</div>

			<button class="user-menu-button logout-button" (click)="logout()">
				<i class="fa fa-sign-out-alt user-menu-icon"></i>
				<span>Logout</span>
			</button>
		</div>
	</div>
</div>
