import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-layout',
	templateUrl: './app-layout.component.html',
	styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnDestroy, OnInit {
	public navigationActive = false;
	public user: User;

	protected readonly isBrowser: boolean;
	protected readonly ngUnsubscribe = new Subject<void>();

	// LEET-2877 retargeting tags
	protected retargetingTagsRendered = false;
	protected viewInitialized = false;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
		protected readonly router: Router,
		protected readonly userService: UserService,
	) {
		this.isBrowser = isPlatformBrowser(platformId);
	}

	protected toggleNavigation(value?: boolean): void {
		this.navigationActive = value === undefined
			? !this.navigationActive
			: value;
	}

	protected onMainContentClick(e: MouseEvent): void {
		if (!this.navigationActive) return;

		e.preventDefault();
		e.stopPropagation();
		this.navigationActive = false;
	}

	protected handleUser(user: User): void {
		this.user = user;
		if (!user) return;

		// LEET-2877 retargeting tags
		this.renderRetargetingTags();
	}

	// LEET-2877 retargeting tags
	protected renderRetargetingTags(): void {
		if (!this.user || !this.isBrowser || this.retargetingTagsRendered || !this.viewInitialized) return;

		this.retargetingTagsRendered = true;

		const el0 = document.createElement('script');
		el0.innerHTML = '!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);},s.version=\'1.1\',s.queue=[],u=t.createElement(n),u.async=!0,u.src=\'https://static.ads-twitter.com/uwt.js\',a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,\'script\');twq(\'config\',\'o5mj2\');';
		document.body.appendChild(el0);

		const el1 = document.createElement('script');
		el1.innerHTML = '_linkedin_partner_id="2885050";window._linkedin_data_partner_ids=window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);';
		document.body.appendChild(el1);

		const el2 = document.createElement('script');
		el2.innerHTML = '(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);';
		document.body.appendChild(el2);
	}

	public ngOnInit(): void {
		// prevents tooltips near corners sometimes causing two vertical scrollbars + a horizontal scrollbar -- not great though
		if (typeof document !== 'undefined') document.querySelector('body').classList.add('--overflow-hidden');

		this.userService.user$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user) => this.handleUser(user));

		// close mobile nav after clicking any link
		this.router.events.pipe(filter((e): e is Scroll => e instanceof Scroll)).subscribe(() => this.navigationActive = false);

		this.handleUser(this.userService.user);
	}

	// LEET-2877 retargeting tags
	public ngAfterViewInit(): void {
		this.viewInitialized = true;
		this.renderRetargetingTags();
	}

	public ngOnDestroy(): void {
		if (typeof document !== 'undefined') document.querySelector('body').classList.remove('--overflow-hidden');

		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
