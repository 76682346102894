import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface BaseToast {
	autohide?: boolean;
	class?: string;
	delay?: number;
	dismissCallback?: () => void;
	interactCallback?: () => void;
}


export interface PlainTextToast extends BaseToast {
	text: string;
	type: undefined;
}

export type Toast = PlainTextToast;

@Injectable({ providedIn: 'root' })
export class ToastService {
	public readonly toastsUpdated$ = new Subject<void>();

	public toasts: Toast[] = [];

	public error(text: string, delay = 5000): void {
		this.show(text, {
			class: 'bg-danger text-white',
			delay,
		});
	}

	public warning(text: string, delay = 5000): void {
		this.show(text, {
			class: 'bg-warning text-white',
			delay,
		});
	}

	public success(text: string, delay = 5000): void {
		this.show(text, {
			class: 'bg-success text-white',
			delay,
		});
	}

	private show(text: string, options: Partial<BaseToast> = {}): void {
		this.toasts.push({ text, ...options as any });
		this.toastsUpdated$.next();
	}

	public remove(toast: any): void {
		this.toasts = this.toasts.filter((t) => t !== toast);
		this.toastsUpdated$.next();
	}
}
