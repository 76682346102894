import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { ToastService } from 'src/app/toast/toast.service';
import { UserService } from 'src/app/services/user.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnboardingStatus } from '../../constants';

@Injectable({
	providedIn: 'root',
})
export class OnboardingCheckService {

	constructor(
		private router: Router,
		private toastService: ToastService,
		private userService: UserService,
	) {}

	public redirectIfNeeded(): void {
		if (!this.userService.user) {
			return this.reloadOnce();
		}

		this.checkAndRedirect(this.userService.user);
	}

	protected checkAndRedirect(user: User): void {
		if (user.onboardingStatus !== OnboardingStatus.COMPLETED) {
			this.router.navigate(['/lol/onboarding']).then(() => console.log('redirect - onboarding'));
			return;
		}

		if (!this.isRiotConnected(user)) {
			this.toastService.error('You need to connect your Riot account to use Leetify - League');
			this.router.navigate(['/lol/onboarding']).then(() => console.log('redirect - onboarding'));
		}
	}

	protected reloadOnce(): void {
		const finished = new Subject<void>();
		combineLatest([this.userService.user$])
			.pipe(takeUntil(finished))
			.subscribe(([user]) => {
				if (!user) return;

				finished.next();
				this.checkAndRedirect(user);
			});

		this.userService.reloadUser();
	}

	public isRiotConnected(user: User) : boolean {
		return !!user.puuid;
	}
}
