import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import { environment } from 'src/environments/environment';
// import { GoogleAnalyticsHelper } from './helpers/ga.helper';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	protected readonly isBrowser: boolean;

	public constructor(
		@Inject(PLATFORM_ID) platformId: Record<string, any>,
	) {
		this.isBrowser = isPlatformBrowser(platformId);

		if (this.isBrowser) {
			// if (environment.production) {
			// 	GoogleAnalyticsHelper.init();
			// }
		}
	}
}
