// benchmarks
export const GREAT = '#00b087';
export const GOOD = '#9ad37f';
export const AVERAGE = '#fff';
export const SUBPAR = '#fb9d5a';
export const POOR = '#de425b';

// brand
export const LEETIFY_FOUNDER_BLUE = '#4c49f8';
export const LEETIFY_PINK = '#f84982';
export const LEETIFY_PURPLE = '#7547b6';

// surfaces
export const SURFACE1 = '#19171e';
export const SURFACE2 = '#201e25';
export const SURFACE3 = '#25222c';
export const SURFACE4 = '#2a2732';
export const SURFACE5 = '#2f2b38';

// match details
export const MATCH_WINNER_COLOR = GREAT;
export const MATCH_LOSER_COLOR = POOR;
export const MATCH_TIE_TEAM_A_COLOR = LEETIFY_PINK;
export const MATCH_TIE_TEAM_B_COLOR = LEETIFY_PURPLE;

export const TEAM_A_BLUE = '#35adf0';
export const TEAM_A_GREEN = '#31da74';
export const TEAM_A_ORANGE = '#ec9240';
export const TEAM_A_PURPLE = '#9b48dc';
export const TEAM_A_YELLOW = '#f5d359';

export const TEAM_B_BLUE = '#336a8a';
export const TEAM_B_GREEN = '#3a694d';
export const TEAM_B_ORANGE = '#8d623a';
export const TEAM_B_PURPLE = '#614479';
export const TEAM_B_YELLOW = '#af953c';

// icons
export enum Icon {
	/* eslint-disable @typescript-eslint/no-shadow */
	AIM = 'aim',
	ALERT = 'alert',
	ALERT_CIRCLE_OUTLINE = 'alert_circle_outline',
	ALLSTAR = 'allstar',
	APPLE = 'apple',
	ARROW_BOTTOM_LEFT = 'arrow_bottom_left',
	ARROW_BOTTOM_RIGHT = 'arrow_bottom_right',
	ARROW_DOWN = 'arrow_down',
	ARROW_LEFT = 'arrow_left',
	ARROW_RIGHT = 'arrow_right',
	ARROW_TOP_LEFT = 'arrow_top_left',
	ARROW_TOP_RIGHT = 'arrow_top_right',
	ARROW_UP = 'arrow_up',
	ARTSTATION = 'artstation',
	BAR_CHART = 'bar_chart',
	BARON = 'baron',
	CALENDAR = 'calendar',
	CHECK = 'check',
	CHECK_CIRCLE = 'check_circle',
	CHEVRON_DOWN = 'chevron_down',
	CHEVRON_LEFT = 'chevron_left',
	CHEVRON_RIGHT = 'chevron_right',
	CHEVRON_UP = 'chevron_up',
	CIRCLE_MEDIUM = 'circle_medium',
	CLOSE = 'close',
	CLOCK = 'clock',
	CLUB_MEMBERS = 'club_members',
	COG = 'cog',
	COMPARE_AIM_RATING = 'compare_aim_rating',
	COMPARE_CLUTCH_RATING = 'compare_clutch_rating',
	COMPARE_OPENING_RATING = 'compare_opening_rating',
	COMPARE_POSITIONING_RATING = 'compare_positioning_rating',
	COMPARE_UTILITY_RATING = 'compare_utility_rating',
	CREEP = 'creep',
	CROWN = 'crown',
	DISCORD = 'discord',
	DRAGON = 'dragon',
	EMAIL = 'email',
	EMOTICON_HAPPY_OUTLINE = 'emoticon_happy_outline',
	ESEA = 'esea',
	ESLGAMING = 'eslgaming',
	ESPORTAL = 'esportal',
	EYE = 'eye',
	EYE_LOCK = 'eye_lock',
	EYE_OFF = 'eye_off',
	FACEBOOK = 'facebook',
	FACEIT = 'faceit',
	FLASH_ASSIST = 'flash_assist',
	GAMERS_CLUB = 'gamers_club',
	GITHUB = 'github',
	GRUB = 'grub',
	HEAD_TO_HEAD_WEAPONS_BREAKDOWN = 'head_to_head_weapons_breakdown',
	HEART = 'heart',
	HELP_CIRCLE = 'help_circle',
	HERALD = 'herald',
	HIGHLIGHT_OF_THE_SESSION = 'highlight_of_the_session',
	HOME = 'home',
	IM_STILL_GONNA_CALL_IT_TWITTER = 'im_still_gonna_call_it_twitter',
	INFORMATION = 'information',
	INFORMATION_OUTLINE = 'information_outline',
	INHIBITOR = 'inhibitor',
	INSTAGRAM = 'instagram',
	KICK = 'kick',
	KILL_FEED_HEADSHOT = 'kill_feed_headshot',
	LEETIFY = 'leetify',
	LINKEDIN = 'linkedin',
	MAP_MARKER = 'map_marker',
	MASTODON = 'mastodon',
	MENU_DOWN = 'menu_down',
	MENU_LEFT = 'menu_left',
	MENU = 'menu',
	MENU_RIGHT = 'menu_right',
	MENU_SWAP = 'menu_swap',
	MENU_UP = 'menu_up',
	MICROPHONE = 'microphone',
	MINUS = 'minus',
	MONEY_HAND = 'money_hand',
	PAPERCLIP = 'paperclip',
	PARTY_FIVE = 'party_five',
	PARTY_FOUR = 'party_four',
	PARTY_ONE = 'party_one',
	PARTY_POPPER = 'party_popper',
	PARTY_THREE = 'party_three',
	PARTY_TWO = 'party_two',
	PIE_CHART = 'pie_chart',
	PIN = 'pin',
	PLACEHOLDER = 'placeholder',
	REDDIT = 'reddit',
	REFRESH = 'refresh',
	ROLE_COMPARISON = 'role_comparison',
	SHARE_FROM_BOX = 'share_from_box',
	SHARE_VARIANT = 'share_variant',
	SPOTIFY = 'spotify',
	STACKED = 'stacked',
	STACK_OVERFLOW = 'stack_overflow',
	STEAM = 'steam',
	SUBDIRECTORY_ARROW_LEFT = 'subdirectory_arrow_left',
	TABLE = 'table',
	TIKTOK = 'tiktok',
	TRASH_CAN_OUTLINE = 'trash_can_outline',
	TURRET = 'turret',
	TWITCH = 'twitch',
	TWITTER = 'twitter',
	UPLOAD = 'upload',
	USER = 'user',
	YOUTUBE = 'youtube',

	// aliases
	/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
	APPLE_MUSIC = 'apple',
	CARET_DOWN = 'menu_down',
	CARET_LEFT = 'menu_left',
	CARET_RIGHT = 'menu_right',
	CARET_UP = 'menu_up',
	CHECKMARK = 'check',
	ENTER = 'subdirectory_arrow_left',
	ESL = 'eslgaming',
	HAMBURGER = 'menu',
	/* eslint-enable @typescript-eslint/no-duplicate-enum-values */
	/* eslint-enable @typescript-eslint/no-shadow */
}

// misc
export const socialMediaLinks: { icon: Icon; link: string; title: string }[] = [
	{ icon: Icon.STEAM, link: 'https://steamcommunity.com/groups/Leetify', title: 'Steam' },
	{ icon: Icon.IM_STILL_GONNA_CALL_IT_TWITTER, link: 'https://twitter.com/leetify', title: 'Twitter' },
	{ icon: Icon.DISCORD, link: 'https://discord.gg/UNygC8BAVg', title: 'Discord' },
	{ icon: Icon.EMAIL, link: 'mailto:support@leetify.com', title: 'Email' },
	{ icon: Icon.YOUTUBE, link: 'https://www.youtube.com/leetify', title: 'YouTube' },
];

// sizes
export const REM_PX = 16;
export const VERTICAL_BAR_CHART_TOOLTIP_PADDING_PX = 1 * REM_PX;
export const VERTICAL_BAR_CHART_VERTICAL_TOOLTIP_PADDING_PX = 1.5 * REM_PX;


/**
 * This should potentially become LOL shared utils
 */
export enum MatchResult {
	WIN = 'win',
	LOSS = 'loss',
	TIE = 'tie',
}

export const S3_BASE_URL = 'http://leetify-lol-assets.auth-3019c2b156144fe3af863840d5f127be.storage.gra.cloud.ovh.net';
// the step at which the user is with the onboarding
export enum OnboardingStates {
	RIOT = 'riot',
	DISCORD = 'discord',
}

export enum OnboardingStatus {
	NEW = 'new',
	ONBOARDING = 'onboarding',
	COMPLETED = 'completed',
}
