import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
	selector: 'app-token-callback',
	templateUrl: '../layout/attempting-login/attempting-login.component.html',
	styleUrls: ['../layout/attempting-login/attempting-login.component.scss'],
})
export class AuthTokenCallbackComponent implements OnInit {

	constructor(
		private router: Router,
		private route: ActivatedRoute,
	) { }

	ngOnInit(): void {
		this.route.params.subscribe((params) => {
			if (!params.token) return;

			AuthService.setToken(params.token);

			this.router.navigate(['/lol']).then(() => console.log('authTokenCallback navigated'));
		});
	}
}
