import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {

	constructor(
		private authService: AuthService,
	) {
	}

	private handleApiRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {

				/*if (error.status === 401) {
					this.authService.logout();

					if (!this.is401Allowed(new URL(req.url))) {
						window.location.href = '/lol';
					}
				}*/

				return throwError(error);
			}),
		);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const isExternalRequest = req.url.includes('http');

		const url = isExternalRequest
			? req.url
			: environment.apiUrl + req.url;

		const reqOpts = { url };
		const apiReq = req.clone(reqOpts);

		return this.handleApiRequest(apiReq, next);
	}

	protected is401Allowed(url: URL): boolean {
		if (url.pathname === '/api/myself') return true;
		if (url.pathname === '/api/am-in') return true;
		if (url.pathname.startsWith('/api/track/')) return true;

		return false;
	}
}
